import { getAuth } from "firebase/auth"
import React, { useEffect, useMemo, useState } from "react"
import { doc, getFirestore, onSnapshot } from "firebase/firestore"
import { useFirestoreRef } from "./firestore"

export function useCurrentUser() {
  const [user, setUser] = useState(getAuth().currentUser)

  useEffect(() => {
      let cancel = false

      getAuth().onAuthStateChanged((user) => {
          if (cancel) return

          if (!user) {
              user = false
          }
          setUser(user)
      })

      return () => cancel = true
  }, [])

  return user
}

export function useApiKey() {
  const [key, setKey] = useState(null)

  useEffect(() => {
    let cancel = false;

    getAuth().onIdTokenChanged((user) => {
      if (!user) {
        setKey(false);
        return;
      }

      user.getIdTokenResult().then((token) => {
        if (cancel) return;
        setKey(token.claims.api_key)
      });
    });

    return () => cancel = true;
  }, []);

  return key;
}

export function useAccountId() {
  const [account, setAccount] = useState(null)

  useEffect(() => {
    let cancel = false;

    getAuth().onIdTokenChanged((user) => {
      if (!user) {
        setAccount(false);
        return;
      }

      user.getIdTokenResult().then((token) => {
        if (cancel) return;
        setAccount(token.claims.account_id)
      });
    });

    return () => cancel = true;
  }, []);

  return account;
}

export function useAccountInfo() {
  const accountId = useAccountId();
  const reference = useMemo(() => {
    if (!accountId) {
      return null;
    }

    return doc(getFirestore(), `accounts/${accountId}`)
  }, [accountId]);

  return useFirestoreRef(reference)
}
