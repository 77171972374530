// A react component that renders the login screen
import React, { useCallback, useEffect, useMemo } from 'react';
import styles from './login-screen.module.css';
import { Flex } from '../components/flex';
import logo from '../assets/logo.png';
import google from '../assets/google.png';
import { Button, ButtonBase, CircularProgress, InputLabel } from '@mui/material';
import { GoogleAuthProvider, getAuth, signInWithPopup, signInWithCustomToken } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";

const params = new Proxy(new URLSearchParams(window.location.search), {
  get: (searchParams, prop) => searchParams.get(prop),
});

export const LoginScreen = React.memo(() => {
  return (
    <Flex className={styles.container}>
      <Flex className={styles.main}>
        <Flex className={styles.left}>
          <img width={125} src={logo} />
        </Flex>
        <Flex className={styles.right}>
          <ContinueWithGoogleButton />
        </Flex>
      </Flex>
      <Flex className={styles.footer}>
        <div>/* code analysis, documentation and optimization. */</div>
      </Flex>
    </Flex>
  );
});

const ContinueWithGoogleButton = React.memo(() => {
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);

  const authenticate = useCallback(async () => {
    try {
      let user = getAuth().currentUser;
      /* check if api_key already available */
      const current_token = await user.getIdTokenResult();
      if (current_token.claims.api_key) {
        return;
      }

      /* generate api_key */
      const functions = getFunctions();
      const getApiToken = httpsCallable(functions, 'getApiToken');
      const invite = params.invite;
      const loginToken = await getApiToken({ invite });

      if (loginToken.data.error) {
        setError(loginToken.data.error);
        throw new Error(loginToken.error.message);
      }

      /* update user */
      const auth = getAuth();
      await signInWithCustomToken(auth, loginToken.data.token);

    } catch (error) {
      return false;
    }
    return true;
  }, []);

  const login = useCallback(async () => {
    setLoading(true);
    try {
      const provider = new GoogleAuthProvider();
      const auth = getAuth();
      const result = await signInWithPopup(auth, provider);
      await authenticate();
    }
    catch (e) {
      console.error(e);
      setError(e.message);
    }
    setLoading(false);
  }
  , [setLoading, authenticate]);

  return (
    <Flex direction={'column'} flex={'none'}>
      <ButtonBase
        variant="contained"
        color="primary"
        className={styles.loginButton}
        onClick={login}
      >
        {loading ? (
          <CircularProgress color='secondary' className={styles.loginButtonIcon} size={40} />
        )
        : (
          <img alt="Continue with Google" height={40} src={google} className={styles.loginButtonIcon} />
        )}
        <div className={styles.loginButtonText}>Continue with Google</div>
      </ButtonBase>
      <InputLabel error={!!error}>{error}</InputLabel>
    </Flex>
  );
});
