import { onSnapshot } from "firebase/firestore";
import React, { useEffect, useState } from "react";

function firestoreDefaultParseResponse(response) {
  if (response.query) {
      return response.docs.map(doc => doc.ref)
  }

  return response
}

function firestoreDefaultParseDocument(document) {
  return document.data()
}

export function useFirestoreRef(reference, parse_doc=firestoreDefaultParseDocument) {
  const [data, setData] = useState(null)

  useEffect(() => {
      if (!reference) return;
      let cancel = false

      onSnapshot(reference, (snapshot) => {
          if (cancel) return;
          const data = firestoreDefaultParseResponse(snapshot)
          if (data) {
              if (data.map) {
                  setData(data.map(doc => parse_doc(doc)))
              } else {
                  setData(parse_doc(data))
              }
          }
          else {
              setData(false)
          }
      })

      return () => {
          cancel = true
          setData(null)
      }
  }, [reference, parse_doc])

  return data
}
