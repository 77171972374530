// A react component that renders the login screen
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { Flex } from '../components/flex';
import styles from './screen-container.module.css';
import logo from '../assets/logo.png';
import { useAccountInfo, useCurrentUser } from '../lib/user';
import { getAuth } from 'firebase/auth';
import { Tooltip } from '@mui/material';

export const ScreenContainer = React.memo(({ children }) => {
  return (
    <Flex className={styles.container}>
      <Flex className={styles.header}>
        <img className={styles.headerLogo} src={logo} />
        <div className={styles.headerTitle}>Studio</div>
        <Flex flex={1}/>
        <CreditsCounter />
        <ProfileImage />
      </Flex>
      {children}
    </Flex>
  );
});

const CreditsCounter = React.memo(() => {
  const accountInfo = useAccountInfo();
  const creditsFormatted = useMemo(() => {
    return accountInfo?.credits?.toLocaleString();
  }, [accountInfo]);

  return (
    <Tooltip title="🪙 Credits">
      <div className={styles.creditsCounter}>
        {creditsFormatted ? creditsFormatted : '...'}
      </div>
    </Tooltip>
  );
});

const ProfileImage = React.memo(() => {
  const user = useCurrentUser();
  return (
    <Menu>
      <Flex className={styles.profileImage}>
        <img className={styles.profileImageImg} referrerPolicy="no-referrer" src={user?.photoURL} />
      </Flex>
    </Menu>
  );
});

const Menu = React.memo(({ children }) => {
  const [open, setOpen] = React.useState(false);
  const menuRef = useRef(null);

  const handleClick = useCallback(() => {
    setOpen(current => !current);
  }, [setOpen]);

  useEffect(() => {
    const onClick = (e) => {
      if (menuRef.current && !menuRef.current.contains(e.target)) {
        setOpen(false);
      }
    }
    document.addEventListener('click', onClick);

    return () => {
      document.removeEventListener('click', onClick);
    }
  }, []);

  const logout = useCallback(() => {
    getAuth().signOut();
  }, []);

  return (
    <div className={styles.menuWrap} onClick={handleClick} ref={menuRef}>
      { children }
      { open && (
        <Flex className={styles.menu}>
          <div className={styles.menuItem} onClick={logout}>
            <div>Logout</div>
          </div>
        </Flex>
      )}
    </div>
  );
});
