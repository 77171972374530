// A react component that renders the home screen.
import React, { useCallback, useEffect, useMemo } from 'react';
import styles from './home-screen.module.css';
import Editor from 'react-simple-code-editor';
import hljs from 'highlight.js';
import 'highlight.js/styles/github-dark.css';
import { Flex } from '../components/flex';
import { Button, CircularProgress, FormControl, InputLabel, MenuItem, Select, Tooltip } from '@mui/material';
import { config } from '../config';
import { useApiKey } from '../lib/user';

const EXAMPLE = `
int FUNC(int n)
{
  if (n <= 1)
    return n;
  return FUNC(n-1) + FUNC(n-2);
}
`.trim();

const ENGINES = {
  coffee: 'Coffee',
  vanilla: 'Vanilla',
};

const useDryRun = (code, mode, engine='coffee') => {
  const api_key = useApiKey();
  const [result, setResult] = React.useState(null);

  useEffect(() => {
    let cancel = false;

    if (!code || !api_key) {
      setResult(null);
      return;
    }

    analyzeCode(api_key, code, mode, engine, true)
    .then(result => {
      if (cancel) {
        return;
      }

      setResult(result);
    }).catch(error => {
      console.log('error', error);
    });

    return () => {
      cancel = true;
    }
  }, [api_key, code, mode, engine]);

  return result;
};

export const HomeScreen = React.memo(() => {
  const [code, setCode] = React.useState(EXAMPLE);
  const [mode, setMode] = React.useState('full');
  const [engine, setEngine] = React.useState('coffee');
  const dry_run = useDryRun(code, mode, engine);
  const tokens = useMemo(() => Math.round(dry_run?.credits * 100) / 100, [dry_run]);

  const handleChange = useCallback(
    (newCode) => {
      setCode(newCode);
    }
  , [setCode]);

  return (
    <Flex className={styles.container}>
      <Flex className={styles.header}>
        <Flex className={styles.headerLeft}>
          <Flex style={{flex: 1}}>Input</Flex>
          <Symbols mode={mode} dry_run={dry_run} />
        </Flex>
        <Flex className={styles.headerRight}>
          <Flex style={{flex: 1}}>Output</Flex>
          <AnalysisModeToggle mode={mode} onChange={setMode} />
          <FormControl
            className={styles.engineSelect}
            color='secondary'
            variant='standard'
            size='small'
          >
            <InputLabel id="engineSelectTitle">Flavour</InputLabel>
            <Select
              labelId="engineSelectTitle"
              className={styles.engineSelect}
              label='Engine'
              value={engine}
              onChange={(e) => setEngine(e.target.value)}
            >
              {Object.keys(ENGINES).map((key) => (
                <MenuItem key={key} value={key}>{ENGINES[key]}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Flex>
      </Flex>
      <Flex className={styles.editors}>
        <Flex className={styles.editorLeft}>
          <CodeEditor code={code} onChange={handleChange} />
          <Tooltip title="Maximum Required Credits" placement='bottom-end'>
            <div className={styles.tokens}>{tokens ? `~${tokens}` : ' '}</div>
          </Tooltip>
        </Flex>
        <ResultViewer code={code} mode={mode} engine={engine} />
      </Flex>
    </Flex>
  );
});

const analyzeCode = async (api_key, code, mode, engine='coffee', dry_run=false) => {
  const modifier_types = mode === 'full' ? [
    'function_rename',
    'function_description',
    'argument_rename',
    'variable_rename',
  ] : [
    'function_rename',
    'function_description',
  ];

  const result = await fetch(`${config.api.url}/v1/analyze_code`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      api_key,
      code,
      modifier_types,
      dry_run,
      engine,
    }),
  });

  const json = await result.json()

  if (result.status !== 200) {
    console.error(`ERROR ${result.status}`, json);
    throw new Error(`ERROR ${result.status}: ${json.detail}`);
  }

  return json;
};

const ResultViewer = React.memo(({ code, mode, engine }) => {
  const [result, setResult] = React.useState(null);
  const [analyseNeeded, setAnalyseNeeded] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const api_key = useApiKey();

  useEffect(() => {
    setAnalyseNeeded(true);
  }, [code, mode, engine]);

  const handleAnalyse = useCallback(() => {
    setLoading(true);
    const cancel = false;

    analyzeCode(api_key, code, mode, engine)
    .then(result => {
      if (cancel) {
        return;
      }

      setResult(result)
      setAnalyseNeeded(false);
    })
    .catch(error => {
      alert(error.message);
    })
    .finally(() => {
      setLoading(false);
    });

    return () => {
      cancel = true;
    };
  }, [api_key, code, mode, engine])

  const resultCode = useMemo(() => {
    let result_code = code.trim();
    if (result?.modifiers) {
      for (const modifier of result.modifiers) {
        if (['function_rename', 'argument_rename', 'variable_rename'].includes(modifier.type)) {
          const old_name = modifier.path.split('.').at(-1)
          result_code = result_code.replaceAll(
            new RegExp(`([^A-Za-z_0-9]|^)(${old_name})([^A-Za-z_0-9]|$)`, 'gm'),
            `$1${modifier.value}$3`);
        }
        else if (modifier.type === 'function_description') {
          result_code = `\/\* ${modifier.value} \*\/\n${result_code}`;
        }
      }

      return result_code;
    }
    return '';
  },[result, code]);

  return (
    <Flex className={`${styles.result} ${styles.editorRight}`}>
      <CodeEditor
        code={resultCode}
        disabled
      />
      {loading ? (
        <div className={styles.overlay}>
          <CircularProgress size={90} />
          <div className={styles.overlayText}>Processing...</div>
        </div>
      ) : analyseNeeded ? (
        <div className={styles.overlay}>
          <Button style={{ textTransform: 'none' }} variant="contained" size='large' onClick={handleAnalyse}>Analyse Function</Button>
          <p>Expected input is a single function, written in C.</p>
        </div>
      ) : null}
    </Flex>
  )
});

const CodeEditor = React.memo(({ code, onChange, ...props }) => {
  const highlight = useCallback(code => {
    try {
      const result = hljs.highlight(`${code}`, { language: 'C++' });
      let rvalue = result.value

      if (code) {
        const function_name = code.match(/[a-zA-Z0-9_]+(?=\s*\()/)[0];

        rvalue = rvalue.replaceAll(function_name, `<u>${function_name}</u>`);
      }

      return `<div>${rvalue}</div>`;
    }
    catch (error) {
      console.log('error', error);
    }
    return code;
  }, []);

  return (
    <Editor
      {...props}
      value={code}
      onValueChange={onChange}
      highlight={highlight}
      padding={10}
      placeholder={`Type your function here...`}
      className={styles.codeEditor}
      style={{
        fontFamily: '"Fira code", "Fira Mono", monospace',
        fontSize: '1em',
        lineHeight: '1.5em',
        cursor: 'pointer',
        margin: '0 auto',
      }}
    />
  );
});

const AnalysisModeToggle = ({ onChange, mode }) => {
  const setMode = useCallback((mode) => {
    onChange(mode);
  }, [onChange]);

  const [styleA, styleB, styleC] = useMemo(() => {
    if (mode === 'full') {
      return [{} , {}, {backgroundColor: '#00FFA3' }];
    }
    else if (mode === 'partial') {
      return [{} , {backgroundColor: '#00A3FF' }, {}];
    }
    return [{ backgroundColor: '#FFF493' }, {}, {}];
  }, [mode]);

  return (
    <Flex className={styles.analysisToggle}>
      <Flex className={styles.analysisToggleText}>{mode}</Flex>
      <Flex className={styles.analysisToggleItem} style={styleB} onClick={() => setMode('partial')} />
      <Flex className={styles.analysisToggleItem} style={styleC} onClick={() => setMode('full')} />
    </Flex>
  )
}

const Symbols = React.memo(({ dry_run, mode }) => {
  const [symbols, setSymbols] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  useEffect(() => {
    if (!dry_run) {
      setSymbols([]);
      return;
    }

    const results = [];

    if (dry_run.parse_info?.functions && dry_run.parse_info.functions.length > 0) {
      const func = dry_run.parse_info.functions[0];
      results.push(...func.variables.map(v => [v.name, 'variable']));
      results.push(...func.parameters.map(v => [v.name, 'argument']));
      results.push([func.name, 'function']);
    }

    setSymbols(results);
  }, [dry_run, mode]);

  return (
    <Flex className={styles.symbols}>
    {symbols.map((symbol, index) => <Symbol title={symbol[0]} key={symbol[0]} description={symbol[1]} />)}
    </Flex>
  );
  });


const Symbol = React.memo(({ title, description }) => {
  return (
      <Flex className={styles.symbol}>
        <Tooltip title={description}>
          <div className={styles.symbolText}>{title}</div>
        </Tooltip>
      </Flex>
  );
});
