// A react component that renders the login screen
import { CircularProgress } from '@mui/material';
import React from 'react';
import { Flex } from '../components/flex';
import styles from './loading-screen.module.css';

export const LoadingScreen = React.memo(() => {
  return (
    <Flex style={{alignItems: 'center', justifyContent: 'center'}} className={styles.container}>
      <CircularProgress size={50} />
    </Flex>
  );
});
