import React, { useMemo } from 'react';
import styles from './flex.module.css';

export const Flex = ({ children, direction, flex, ...props }) => {
  const className = useMemo(() => props.className ? `${styles.flex} ${props.className}` : styles.flex, [props.className]);

  const style = useMemo(() => {
    const s = props.style ? props.style : {};
    if (direction) {
      s.flexDirection = direction;
    }
    if (flex) {
      s.flex = flex;
    }
    return s;
  }, [direction, flex, props.style]);

  return (
    <div {...props} className={className} style={style}>
      {children}
    </div>
  );
}
