import logo from './logo.svg';
import './App.css';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { LoginScreen } from './screens/login-screen';
import { HomeScreen } from './screens/home-screen';
import { LoadingScreen } from './screens/loading-screen';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useApiKey } from './lib/user';
import { ScreenContainer } from './screens/screen-container';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

function App() {
  const loggedIn = useApiKey();
  const loading = loggedIn === null;

  return (
    <ThemeProvider theme={darkTheme}>
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route index element={loading ? <LoadingScreen /> : loggedIn ? <ScreenContainer><HomeScreen /></ScreenContainer> : <LoginScreen />} />
            { /*
              <Route path="blogs" element={<Blogs />} />
            */ }
          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
